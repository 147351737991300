import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, css } from '@emotion/react';

import {
  Pump,
  Valve,
  Pipe,
  PipePlaceholder,
  MiddlePipeL,
  WaterTank,
  AirTank,
  Compressor,
  TriplePipeFork,
  DoublePipeFork,
  PipeFork,
  ConnectionIndicator,
  ValveConnect,
  Pond,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  MultiInfo,
  // Totals,
  Statistic,
  MobileStatTable,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
  MultiColInfo,
  Alarms,
} from 'isno/lib/components/displays';

import {
  InfoControl,
  MultiInfoButton,
  VFDPumpControl,
  ToggleControl,
  AlarmReset,
  MultiSetpointControl,
  InfoButton,
} from 'isno/lib/components/controls';

import { startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { PumpsRowCustom } from 'isno/lib/components/layout/Rows';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
function PrimaryScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });
  const [p1HighLow, setP1HighLow] = useState(null);
  const [p3HighLow, setP3HighLow] = useState(null);
  const [p4HighLow, setP4HighLow] = useState(null);

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(1),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    if (props.locations?.locations?.[1]?.equipment?.['P4 HZ']?.ioValues?.hz_disable_enable?.value === 1) {
      setP4HighLow('high');
    } else {
      setP4HighLow('low');
    }
  }, [props.locations?.locations?.[1]?.equipment?.['P4 HZ']?.ioValues?.hz_disable_enable?.value]);

  useEffect(() => {
    if (props.locations?.locations?.[1]?.equipment?.['P3 HZ']?.ioValues?.hz_disable_enable?.value === 1) {
      setP3HighLow('high');
    } else {
      setP3HighLow('low');
    }
  }, [props.locations?.locations?.[1]?.equipment?.['P3 HZ']?.ioValues?.hz_disable_enable?.value]);

  useEffect(() => {
    if (props.locations?.locations?.[1]?.equipment?.['P1 HZ']?.ioValues?.hz_disable_enable?.value === 1) {
      setP1HighLow('high');
    } else {
      setP1HighLow('low');
    }
  }, [props.locations?.locations?.[1]?.equipment?.['P1 HZ']?.ioValues?.hz_disable_enable?.value]);

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(1);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const pumpMainStatusKey = {
    0: 'READY',
    1: 'RUN',
    2: 'Switch',
    3: 'High Temp',
    4: 'Low Level',
    5: 'Run Fail',
    6: 'Disabled',
    7: 'Comm',
    8: 'Local',
    9: 'Fault',
    10: 'Suction',
    11: 'PRIMARY',
  };

  const pumpMainStatusColorKey = {
    0: `${theme.onGreen}`,
    1: `${theme.onGreen}`,
    2: `${theme.alarm}`,
    3: `${theme.alarm}`,
    4: `${theme.alarm}`,
    5: `${theme.alarm}`,
    6: `${theme.alarm}`,
    7: `${theme.alarm}`,
    8: `${theme.alarm}`,
    9: `${theme.alarm}`,
    10: `${theme.alarm}`,
    11: `${theme.onGreen}`,
  };

  const locationEquipment1 = props.locations?.locations?.[1]?.equipment;
  if (!locationEquipment1) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  const useCelsius = props.settings?.settings?.useCelsius;

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label="Upper System">
            <InfoControlRow>
              <VFDPumpControl
                title="PID Control"
                pumpData={{ id: `controlValve_${locationEquipment1?.['PID HZ']?.id}` }}
                pidData={locationEquipment1?.['PID HZ']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_mode',
                  manualMode: 'manual_mode',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                }}
                readValues={[1, 1]}
                writeValues={[0, 1]}
              />
              <Info
                title="PID Fill Mode"
                statistic={locationEquipment1?.['PID HZ']?.ioValues?.fill_mode_done?.value === 1 ? 'Done' : 'Filling'}
                color={locationEquipment1?.['PID HZ']?.ioValues?.fill_mode_done?.value === 1 ? theme.onGreen : theme.waterColor}
              />
              <div css={css`display: inline-grid; flex-direction: column;`}>
                <Info
                  title="Voltage"
                  statistic={locationEquipment1?.['Other HZ']?.ioValues?.voltage?.value}
                  label={locationEquipment1?.['Other HZ']?.ioValues?.voltage?.ioValueType?.units}
                />
                <Info
                  title="Current"
                  statistic={locationEquipment1?.['Other HZ']?.ioValues?.current?.value}
                  label={locationEquipment1?.['Other HZ']?.ioValues?.current?.ioValueType?.units}
                />
              </div>
              <MultiInfo
                title="Pump Timers"
                subtitles={['Next Start', 'Next Stop']}
                statistics={[
                  locationEquipment1?.['Other HZ']?.ioValues?.pump_starting_timer?.value,
                  locationEquipment1?.['Other HZ']?.ioValues?.pump_stopping_timer?.value,
                ]}
                ids={[
                  locationEquipment1?.['Other HZ']?.ioValues?.pump_starting_timer?.id,
                  locationEquipment1?.['Other HZ']?.ioValues?.pump_stopping_timer?.id,
                ]}
                labels={[
                  locationEquipment1?.['Other HZ']?.ioValues?.pump_starting_timer?.ioValueType?.units,
                  locationEquipment1?.['Other HZ']?.ioValues?.pump_stopping_timer?.ioValueType?.units,
                ]}
              />
              <MultiColInfo
                title="Discharge Flow Volumes"
                firstColSubtitles={['Today', 'Monthly', 'Yearly']}
                secondColSubtitles={['Yesterday', 'Last Month', 'Last Year']}
                thirdColSubtitles={['Total']}
                firstColStatistics={[
                  locationEquipment1?.['FT HZ']?.ioValues?.daily_volume?.value,
                  locationEquipment1?.['FT HZ']?.ioValues?.monthly_volume?.value,
                  locationEquipment1?.['FT HZ']?.ioValues?.yearly_volume?.value,
                ]}
                secondColStatistics={[
                  locationEquipment1?.['FT HZ']?.ioValues?.yesterday_volume?.value,
                  locationEquipment1?.['FT HZ']?.ioValues?.last_month_volume?.value,
                  locationEquipment1?.['FT HZ']?.ioValues?.last_year_volume?.value,
                ]}
                thirdColStatistics={[
                  locationEquipment1?.['FT HZ']?.ioValues?.total_volume?.value,
                ]}
                firstColLabels={[
                  locationEquipment1?.['FT HZ']?.ioValues?.daily_volume?.ioValueType?.units,
                  locationEquipment1?.['FT HZ']?.ioValues?.monthly_volume?.ioValueType?.units,
                  locationEquipment1?.['FT HZ']?.ioValues?.yearly_volume?.ioValueType?.units,
                ]}
                secondColLabels={[
                  locationEquipment1?.['FT HZ']?.ioValues?.yesterday_volume?.ioValueType?.units,
                  locationEquipment1?.['FT HZ']?.ioValues?.last_month_volume?.ioValueType?.units,
                  locationEquipment1?.['FT HZ']?.ioValues?.last_year_volume?.ioValueType?.units,
                ]}
                thirdColLabels={[
                  locationEquipment1?.['FT HZ']?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                firstColIds={[
                  locationEquipment1?.['FT HZ']?.ioValues?.daily_volume?.id,
                  locationEquipment1?.['FT HZ']?.ioValues?.monthly_volume?.id,
                  locationEquipment1?.['FT HZ']?.ioValues?.yearly_volume?.id,
                ]}
                secondColIds={[
                  locationEquipment1?.['FT HZ']?.ioValues?.yesterday_volume?.id,
                  locationEquipment1?.['FT HZ']?.ioValues?.last_month_volume?.id,
                  locationEquipment1?.['FT HZ']?.ioValues?.last_year_volume?.id,
                ]}
                thirdColIds={[
                  locationEquipment1?.['FT HZ']?.ioValues?.total_volume?.id,
                ]}
                firstColPrecisions={[0, 0, 0]}
                secondColPrecisions={[0, 0, 0]}
                thirdColPrecisions={[1]}
              />
              <InfoButton
                title="Trip Vol."
                statistic={locationEquipment1?.['FT HZ']?.ioValues?.trip_volume?.value}
                label={locationEquipment1?.['FT HZ']?.ioValues?.trip_volume?.ioValueType?.units}
                precision={0}
                buttonWriteId={locationEquipment1?.['FT HZ']?.ioValues?.trip_volume_reset?.id}
                buttonText="Reset"
                setIOValue={props.setIOValue}
              />
              <Alarms
                title="General Alarms"
                ioValues={locationEquipment1?.['Other HZ']?.ioValues}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationEquipment1?.['Other HZ']}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'alarm_reset',
                }}
                resetWriteValue={1}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[2], language)}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[2], theme)}
              />
              <MobileStatTable
                title="Upper Water Metrics"
                stats={
                [
                  {
                    description: 'Discharge Water PSI',
                    stat: locationEquipment1?.['Other HZ']?.ioValues.discharge_water_pressure?.value,
                    label: locationEquipment1?.['Other HZ']?.ioValues.discharge_water_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Discharge Water Flow',
                    stat: locationEquipment1?.['Other HZ']?.ioValues.hz_discharge_water_flow?.value,
                    label: locationEquipment1?.['Other HZ']?.ioValues.hz_discharge_water_flow?.ioValueType?.units,
                  },
                  {
                    description: 'Discharge Water Temp.',
                    stat: useCelsius ? locationEquipment1?.['Other HZ']?.ioValues.discharge_water_temperature_alternate?.value : locationEquipment1?.['Other HZ']?.ioValues.discharge_water_temperature?.value,
                    label: useCelsius ? locationEquipment1?.['Other HZ']?.ioValues.discharge_water_temperature_alternate?.ioValueType?.units : locationEquipment1?.['Other HZ']?.ioValues.discharge_water_temperature?.ioValueType?.units,
                    precision: 1,
                  },
                ]
              }
              />
            </InfoControlRow>
          </LabeledContainer>
        </BasicRow>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label="Lower System">
            <InfoControlRow>
              <VFDPumpControl
                title="PID Control"
                pumpData={{ id: `controlValve_${locationEquipment1?.['PID LZ']?.id}` }}
                pidData={locationEquipment1?.['PID LZ']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_mode',
                  manualMode: 'manual_mode',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                }}
                readValues={[1, 1]}
                writeValues={[0, 1]}
              />
              <Info
                title="PID Fill Mode"
                statistic={locationEquipment1?.['PID LZ']?.ioValues?.fill_mode_done?.value === 1 ? 'Done' : 'Filling'}
                color={locationEquipment1?.['PID LZ']?.ioValues?.fill_mode_done?.value === 1 ? theme.onGreen : theme.waterColor}
              />
              <div css={css`display: inline-grid; flex-direction: column;`}>
                <Info
                  title="Voltage"
                  statistic={locationEquipment1?.['Other LZ']?.ioValues?.voltage?.value}
                  label={locationEquipment1?.['Other LZ']?.ioValues?.voltage?.ioValueType?.units}
                />
                <Info
                  title="Current"
                  statistic={locationEquipment1?.['Other LZ']?.ioValues?.current?.value}
                  label={locationEquipment1?.['Other LZ']?.ioValues?.current?.ioValueType?.units}
                />
              </div>
              <MultiInfo
                title="Pump Timers"
                subtitles={['Next Start', 'Next Stop']}
                statistics={[
                  locationEquipment1?.['Other LZ']?.ioValues?.pump_starting_timer?.value,
                  locationEquipment1?.['Other LZ']?.ioValues?.pump_stopping_timer?.value,
                ]}
                ids={[
                  locationEquipment1?.['Other LZ']?.ioValues?.pump_starting_timer?.id,
                  locationEquipment1?.['Other LZ']?.ioValues?.pump_stopping_timer?.id,
                ]}
                labels={[
                  locationEquipment1?.['Other LZ']?.ioValues?.pump_starting_timer?.ioValueType?.units,
                  locationEquipment1?.['Other LZ']?.ioValues?.pump_stopping_timer?.ioValueType?.units,
                ]}
              />
              <MultiColInfo
                title="Discharge Flow Volumes"
                firstColSubtitles={['Today', 'Monthly', 'Yearly']}
                secondColSubtitles={['Yesterday', 'Last Month', 'Last Year']}
                thirdColSubtitles={['Total']}
                firstColStatistics={[
                  locationEquipment1?.['FT LZ']?.ioValues?.daily_volume?.value,
                  locationEquipment1?.['FT LZ']?.ioValues?.monthly_volume?.value,
                  locationEquipment1?.['FT LZ']?.ioValues?.yearly_volume?.value,
                ]}
                secondColStatistics={[
                  locationEquipment1?.['FT LZ']?.ioValues?.yesterday_volume?.value,
                  locationEquipment1?.['FT LZ']?.ioValues?.last_month_volume?.value,
                  locationEquipment1?.['FT LZ']?.ioValues?.last_year_volume?.value,
                ]}
                thirdColStatistics={[
                  locationEquipment1?.['FT LZ']?.ioValues?.total_volume?.value,
                ]}
                firstColLabels={[
                  locationEquipment1?.['FT LZ']?.ioValues?.daily_volume?.ioValueType?.units,
                  locationEquipment1?.['FT LZ']?.ioValues?.monthly_volume?.ioValueType?.units,
                  locationEquipment1?.['FT LZ']?.ioValues?.yearly_volume?.ioValueType?.units,
                ]}
                secondColLabels={[
                  locationEquipment1?.['FT LZ']?.ioValues?.yesterday_volume?.ioValueType?.units,
                  locationEquipment1?.['FT LZ']?.ioValues?.last_month_volume?.ioValueType?.units,
                  locationEquipment1?.['FT LZ']?.ioValues?.last_year_volume?.ioValueType?.units,
                ]}
                thirdColLabels={[
                  locationEquipment1?.['FT LZ']?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                firstColIds={[
                  locationEquipment1?.['FT LZ']?.ioValues?.daily_volume?.id,
                  locationEquipment1?.['FT LZ']?.ioValues?.monthly_volume?.id,
                  locationEquipment1?.['FT LZ']?.ioValues?.yearly_volume?.id,
                ]}
                secondColIds={[
                  locationEquipment1?.['FT LZ']?.ioValues?.yesterday_volume?.id,
                  locationEquipment1?.['FT LZ']?.ioValues?.last_month_volume?.id,
                  locationEquipment1?.['FT LZ']?.ioValues?.last_year_volume?.id,
                ]}
                thirdColIds={[
                  locationEquipment1?.['FT LZ']?.ioValues?.total_volume?.id,
                ]}
                firstColPrecisions={[0, 0, 0]}
                secondColPrecisions={[0, 0, 0]}
                thirdColPrecisions={[1]}
              />
              <InfoButton
                title="Trip Vol."
                statistic={locationEquipment1?.['FT LZ']?.ioValues?.trip_volume?.value}
                label={locationEquipment1?.['FT LZ']?.ioValues?.trip_volume?.ioValueType?.units}
                precision={0}
                buttonWriteId={locationEquipment1?.['FT LZ']?.ioValues?.trip_volume_reset?.id}
                buttonText="Reset"
                setIOValue={props.setIOValue}
              />
              <Info
                title="LZ + HZ Volume"
                statistic={locationEquipment1?.['Other LZ']?.ioValues?.total_volume_hz_and_lz?.value}
                label={locationEquipment1?.['Other LZ']?.ioValues?.total_volume_hz_and_lz?.ioValueType?.units}
              />
              <Info
                title="Room Temp."
                statistic={useCelsius ? locationEquipment1?.['Other LZ']?.ioValues?.room_temperature_alternate?.value : locationEquipment1?.['Other LZ']?.ioValues?.room_temperature?.value}
                label={useCelsius ? locationEquipment1?.['Other LZ']?.ioValues?.room_temperature_alternate?.ioValueType?.units : locationEquipment1?.['Other LZ']?.ioValues?.room_temperature?.ioValueType?.units}
                precision={1}
              />
              <Alarms
                title="General Alarms"
                ioValues={locationEquipment1?.['Other LZ']?.ioValues}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationEquipment1?.['Other LZ']}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'alarm_reset',
                }}
                resetWriteValue={1}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[1], language)}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[1], theme)}
              />
              <MobileStatTable
                title="Lower Water Metrics"
                stats={
                [
                  {
                    description: 'Suction Water PSI',
                    stat: locationEquipment1?.['Other LZ']?.ioValues.suction_water_pressure?.value,
                    label: locationEquipment1?.['Other LZ']?.ioValues.suction_water_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Discharge Water PSI',
                    stat: locationEquipment1?.['Other LZ']?.ioValues.discharge_water_pressure?.value,
                    label: locationEquipment1?.['Other LZ']?.ioValues.discharge_water_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Discharge Water Flow',
                    stat: locationEquipment1?.['Other LZ']?.ioValues.lz_discharge_water_flow?.value,
                    label: locationEquipment1?.['Other LZ']?.ioValues.lz_discharge_water_flow?.ioValueType?.units,
                  },
                  {
                    description: 'Discharge Water Temp.',
                    stat: useCelsius ? locationEquipment1?.['Other LZ']?.ioValues.discharge_water_temperature_alternate?.value : locationEquipment1?.['Other LZ']?.ioValues.discharge_water_temperature?.value,
                    label: useCelsius ? locationEquipment1?.['Other LZ']?.ioValues.discharge_water_temperature_alternate?.ioValueType?.units : locationEquipment1?.['Other LZ']?.ioValues.discharge_water_temperature?.ioValueType?.units,
                    precision: 1,
                  },
                ]
              }
              />
            </InfoControlRow>
            <PumpsRowCustom height="100px">
              <div css={styles.valveCorner}>
                <ValveConnect
                  pipeAbove="right"
                  nameTop="-10px"
                  nameLeft="50px"
                  percent
                  percentTop="24px"
                  percentLeft="47px"
                  valveData={locationEquipment1?.BV34}
                  IOValueKeys={{
                    opened: 'opened',
                    closed: 'closed',
                  }}
                  readValues={[1, 1]}
                  valveStateKeys={{
                    0: 'Closed',
                    1: 'Open',
                    2: 'Moving',
                  }}
                  redImageKey="Closed"
                  greenImageKey="Open"
                  yellowImageKey="Moving"
                />
              </div>
              <PipePlaceholder horizontalAbove />
              <div css={styles.valveCorner}>
                <ValveConnect
                  pipeAbove="middle"
                  nameTop="-10px"
                  nameLeft="50px"
                  percent
                  percentTop="24px"
                  percentLeft="47px"
                  valveData={locationEquipment1?.BV33}
                  IOValueKeys={{
                    opened: 'opened',
                    closed: 'closed',
                  }}
                  readValues={[1, 1]}
                  valveStateKeys={{
                    0: 'Closed',
                    1: 'Open',
                    2: 'Moving',
                  }}
                  redImageKey="Closed"
                  greenImageKey="Open"
                  yellowImageKey="Moving"
                />
              </div>
              <PipePlaceholder horizontalAbove />
              <div css={styles.valveCorner}>
                <PipePlaceholder horizontalAbove minWidth="0px" />
              </div>
              <PipePlaceholder horizontalAbove />
              <div css={styles.valveCorner}>
                <ValveConnect
                  pipeAbove="middle"
                  nameTop="-10px"
                  nameLeft="50px"
                  percent
                  percentTop="24px"
                  percentLeft="47px"
                  valveData={locationEquipment1?.BV31}
                  IOValueKeys={{
                    opened: 'opened',
                    closed: 'closed',
                  }}
                  readValues={[1, 1]}
                  valveStateKeys={{
                    0: 'Closed',
                    1: 'Open',
                    2: 'Moving',
                  }}
                  redImageKey="Closed"
                  greenImageKey="Open"
                  yellowImageKey="Moving"
                />
              </div>
              <PipePlaceholder horizontalAbove />
              <div css={styles.flexRowBox}>
                <PipePlaceholder horizontalAbove minWidth="0px" />
                <div css={[css`display: flex; flex-direction: row; margin-top: -3px;`, styles.hiddenMobile]}>
                  <Statistic
                    border
                    statistic={locationEquipment1?.['Other HZ']?.ioValues?.discharge_water_pressure?.value}
                    label={locationEquipment1?.['Other HZ']?.ioValues?.discharge_water_pressure?.ioValueType?.units}
                  />
                  <Statistic
                    border
                    statistic={locationEquipment1?.['Other HZ']?.ioValues?.hz_discharge_water_flow?.value}
                    label={locationEquipment1?.['Other HZ']?.ioValues?.hz_discharge_water_flow?.ioValueType?.units}
                  />
                  <Statistic
                    border
                    statistic={useCelsius ? locationEquipment1?.['Other HZ']?.ioValues?.discharge_water_temperature_alternate?.value : locationEquipment1?.['Other HZ']?.ioValues?.discharge_water_temperature?.value}
                    label={useCelsius ? locationEquipment1?.['Other HZ']?.ioValues?.discharge_water_temperature_alternate?.ioValueType?.units : locationEquipment1?.['Other HZ']?.ioValues?.discharge_water_temperature?.ioValueType?.units}
                    precision={1}
                  />
                </div>
                <PipePlaceholder horizontalAbove minWidth="0px" capAfter capLabel="Upper System" capLabelLeft="-110px" capLabelTop="19px" />
              </div>
            </PumpsRowCustom>
            <PumpsRowCustom height="100px">
              <div css={styles.valveCorner}>
                <PipePlaceholder pipeBelow="right" minWidth="0px" />
              </div>
              <ValveConnect
                pipeAbove="right"
                pipeBelow="left"
                nameTop="-10px"
                nameLeft="50px"
                percent
                percentTop="24px"
                percentLeft="47px"
                connectBelow
                valveData={locationEquipment1?.BV24}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                }}
                readValues={[1, 1]}
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Open',
                  2: 'Moving',
                }}
                redImageKey="Closed"
                greenImageKey="Open"
                yellowImageKey="Moving"
              />
              <div css={styles.valveCorner}>
                <PipePlaceholder pipeBelow="right" pipeAbove="middle" minWidth="0px" />
              </div>
              <ValveConnect
                pipeAbove="middle"
                pipeBelow="left"
                nameTop="-10px"
                nameLeft="50px"
                percent
                percentTop="24px"
                percentLeft="47px"
                connectBelow
                valveData={locationEquipment1?.BV23}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                }}
                readValues={[1, 1]}
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Open',
                  2: 'Moving',
                }}
                redImageKey="Closed"
                greenImageKey="Open"
                yellowImageKey="Moving"
              />
              <div css={styles.valveCorner}>
                <PipePlaceholder horizontalAbove minWidth="0px" />
              </div>
              <PipePlaceholder pipeAbove="middle" />
              <div css={styles.valveCorner}>
                <PipePlaceholder pipeBelow="right" pipeAbove="middle" minWidth="0px" />
              </div>
              <ValveConnect
                pipeAbove="middle"
                pipeBelow="left"
                nameTop="-10px"
                nameLeft="50px"
                percent
                percentTop="24px"
                percentLeft="47px"
                connectBelow
                valveData={locationEquipment1?.BV21}
                IOValueKeys={{
                  opened: 'opened',
                  closed: 'closed',
                }}
                readValues={[1, 1]}
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Open',
                  2: 'Moving',
                }}
                redImageKey="Closed"
                greenImageKey="Open"
                yellowImageKey="Moving"
              />
              <div css={styles.flexRowBox}>
                <PipePlaceholder horizontalAbove minWidth="0px" />
                <div css={[css`display: flex; flex-direction: row; margin-top: -3px;`, styles.hiddenMobile]}>
                  <Statistic
                    border
                    statistic={locationEquipment1?.['Other LZ']?.ioValues?.discharge_water_pressure?.value}
                    label={locationEquipment1?.['Other LZ']?.ioValues?.discharge_water_pressure?.ioValueType?.units}
                  />
                  <Statistic
                    border
                    statistic={locationEquipment1?.['Other LZ']?.ioValues?.lz_discharge_water_flow?.value}
                    label={locationEquipment1?.['Other LZ']?.ioValues?.lz_discharge_water_flow?.ioValueType?.units}
                  />
                  <Statistic
                    border
                    statistic={useCelsius ? locationEquipment1?.['Other LZ']?.ioValues?.discharge_water_temperature_alternate?.value : locationEquipment1?.['Other LZ']?.ioValues?.discharge_water_temperature?.value}
                    label={useCelsius ? locationEquipment1?.['Other LZ']?.ioValues?.discharge_water_temperature_alternate?.ioValueType?.units : locationEquipment1?.['Other LZ']?.ioValues?.discharge_water_temperature?.ioValueType?.units}
                    precision={1}
                  />
                </div>
                <PipePlaceholder horizontalAbove minWidth="0px" capAfter capLabel="Lower System" capLabelLeft="-110px" capLabelTop="19px" />
              </div>
            </PumpsRowCustom>
            <PumpsRowCustom height="175px">
              <div css={styles.valveCorner}>
                <PipePlaceholder hidden minWidth="0px" />
              </div>
              {p4HighLow === 'high' && (
                <Pump
                  pipeBelow="right"
                  localRemote
                  buttonText1="HZ"
                  buttonText2="LZ"
                  controlPositionTop="-60px"
                  setIOValue={props.setIOValue}
                  pumpData={{
                    ...locationEquipment1?.['P4 HZ'],
                    name: 'P4',
                    ioValues: {
                      ...locationEquipment1?.['P4 HZ']?.ioValues,
                      start: locationEquipment1?.['P4 LZ']?.ioValues?.disable_enable,
                      stop: locationEquipment1?.['P4 LZ']?.ioValues?.disable_enable,
                      local: locationEquipment1?.['P4 HZ']?.ioValues?.hz_disable_enable,
                      remote: locationEquipment1?.['P4 HZ']?.ioValues?.lz_disable_enable,
                    },
                  }}
                  otherData={locationEquipment1?.['Other HZ']}
                  stateKey={{
                    0: language.off,
                    1: language.on,
                    2: language.warning,
                    3: language.alarm,
                  }}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'enabled',
                    stopped: 'enabled',
                    local: 'local',
                    remote: 'remote',
                    speed: 'speed',
                  }}
                  writeValues={[
                    1,
                    0,
                    locationEquipment1?.['P4 HZ']?.ioValues?.hz_disable_enable?.value === 1 ? 0 : 1,
                    locationEquipment1?.['P4 HZ']?.ioValues?.lz_disable_enable?.value === 1 ? 0 : 1,
                  ]}
                  readValues={[1, 0, 1, 1]}
                  alarms={[
                    locationEquipment1?.['P4 HZ']?.ioValues?.high_seal_temperature,
                    locationEquipment1?.['P4 HZ']?.ioValues?.low_suction_pressure,
                    locationEquipment1?.['P4 HZ']?.ioValues?.fail_to_run,
                    locationEquipment1?.['P4 HZ']?.ioValues?.speed_signal_fault,
                    locationEquipment1?.['P4 HZ']?.ioValues?.high_motor_temperature,
                    locationEquipment1?.['P4 HZ']?.ioValues?.low_suction_pressure_fault,
                    locationEquipment1?.['P4 HZ']?.ioValues?.modbus_communication_error,
                  ]}
                  stats={[
                    {
                      stat: locationEquipment1?.['P4 HZ']?.ioValues?.current?.value,
                      label: locationEquipment1?.['P4 HZ']?.ioValues?.current?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment1?.['P4 HZ']?.ioValues?.run_time?.value,
                      label: locationEquipment1?.['P4 HZ']?.ioValues?.run_time?.ioValueType?.units,
                    },
                  ]}
                  statuses={[
                    {
                      id: locationEquipment1?.['P4 HZ']?.ioValues?.status?.id,
                      status: locationEquipment1?.['P4 HZ']?.ioValues?.status?.value,
                      statusKey: pumpMainStatusKey,
                      statusColorKey: pumpMainStatusColorKey,
                    },
                    {
                      id: locationEquipment1?.['P4 HZ']?.ioValues?.auto_enabled?.id,
                      status: locationEquipment1?.['P4 HZ']?.ioValues?.auto_enabled?.value,
                      statusKey: {
                        0: '',
                        1: language.auto,
                      },
                      statusColorKey: {
                        0: '',
                        1: theme.auto,
                      },
                    },
                  ]}
                />
              )}
              {p4HighLow === 'low' && (
                <Pump
                  pipeBelow="right"
                  localRemote
                  buttonText1="HZ"
                  buttonText2="LZ"
                  controlPositionTop="-60px"
                  setIOValue={props.setIOValue}
                  pumpData={{
                    ...locationEquipment1?.['P4 LZ'],
                    name: 'P4',
                    ioValues: {
                      ...locationEquipment1?.['P4 LZ']?.ioValues,
                      local: locationEquipment1?.['P4 HZ']?.ioValues?.hz_disable_enable,
                      remote: locationEquipment1?.['P4 HZ']?.ioValues?.lz_disable_enable,
                    },
                  }}
                  otherData={locationEquipment1?.['Other LZ']}
                  stateKey={{
                    0: language.off,
                    1: language.on,
                    2: language.warning,
                    3: language.alarm,
                  }}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    start: 'disable_enable',
                    stop: 'disable_enable',
                    started: 'enabled',
                    stopped: 'enabled',
                    local: 'local',
                    remote: 'remote',
                    speed: 'speed',
                  }}
                  writeValues={[
                    1,
                    0,
                    locationEquipment1?.['P4 HZ']?.ioValues?.hz_disable_enable?.value === 1 ? 0 : 1,
                    locationEquipment1?.['P4 HZ']?.ioValues?.lz_disable_enable?.value === 1 ? 0 : 1,
                  ]}
                  readValues={[1, 0, 1, 1]}
                  alarms={[
                    locationEquipment1?.['P4 LZ']?.ioValues?.high_seal_temperature,
                    locationEquipment1?.['P4 LZ']?.ioValues?.low_suction_pressure,
                    locationEquipment1?.['P4 LZ']?.ioValues?.fail_to_run,
                    locationEquipment1?.['P4 LZ']?.ioValues?.speed_signal_fault,
                    locationEquipment1?.['P4 LZ']?.ioValues?.high_motor_temperature,
                    locationEquipment1?.['P4 LZ']?.ioValues?.low_suction_pressure_fault,
                    locationEquipment1?.['P4 LZ']?.ioValues?.modbus_communication_error,
                  ]}
                  stats={[
                    {
                      stat: locationEquipment1?.['P4 LZ']?.ioValues?.current?.value,
                      label: locationEquipment1?.['P4 LZ']?.ioValues?.current?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment1?.['P4 LZ']?.ioValues?.run_time?.value,
                      label: locationEquipment1?.['P4 LZ']?.ioValues?.run_time?.ioValueType?.units,
                    },
                  ]}
                  statuses={[
                    {
                      id: locationEquipment1?.['P4 LZ']?.ioValues?.status?.id,
                      status: locationEquipment1?.['P4 LZ']?.ioValues?.status?.value,
                      statusKey: pumpMainStatusKey,
                      statusColorKey: pumpMainStatusColorKey,
                    },
                    {
                      id: locationEquipment1?.['P4 LZ']?.ioValues?.auto_enabled?.id,
                      status: locationEquipment1?.['P4 LZ']?.ioValues?.auto_enabled?.value,
                      statusKey: {
                        0: '',
                        1: language.auto,
                      },
                      statusColorKey: {
                        0: '',
                        1: theme.auto,
                      },
                    },
                  ]}
                />
              )}
              <div css={styles.valveCorner}>
                <PipePlaceholder horizontalBelow minWidth="0px" />
              </div>
              {p3HighLow === 'high' && (
                <Pump
                  pipeBelow="middle"
                  localRemote
                  buttonText1="HZ"
                  buttonText2="LZ"
                  controlPositionTop="-60px"
                  setIOValue={props.setIOValue}
                  pumpData={{
                    ...locationEquipment1?.['P3 HZ'],
                    name: 'P3',
                    ioValues: {
                      ...locationEquipment1?.['P3 HZ']?.ioValues,
                      start: locationEquipment1?.['P3 LZ']?.ioValues?.disable_enable,
                      stop: locationEquipment1?.['P3 LZ']?.ioValues?.disable_enable,
                      local: locationEquipment1?.['P3 HZ']?.ioValues?.hz_disable_enable,
                      remote: locationEquipment1?.['P3 HZ']?.ioValues?.lz_disable_enable,
                    },
                  }}
                  otherData={locationEquipment1?.['Other HZ']}
                  stateKey={{
                    0: language.off,
                    1: language.on,
                    2: language.warning,
                    3: language.alarm,
                  }}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'enabled',
                    stopped: 'enabled',
                    local: 'local',
                    remote: 'remote',
                    speed: 'speed',
                  }}
                  writeValues={[
                    1,
                    0,
                    locationEquipment1?.['P3 HZ']?.ioValues?.hz_disable_enable?.value === 1 ? 0 : 1,
                    locationEquipment1?.['P3 HZ']?.ioValues?.lz_disable_enable?.value === 1 ? 0 : 1,
                  ]}
                  readValues={[1, 0, 1, 1]}
                  alarms={[
                    locationEquipment1?.['P3 HZ']?.ioValues?.high_seal_temperature,
                    locationEquipment1?.['P3 HZ']?.ioValues?.low_suction_pressure,
                    locationEquipment1?.['P3 HZ']?.ioValues?.fail_to_run,
                    locationEquipment1?.['P3 HZ']?.ioValues?.speed_signal_fault,
                    locationEquipment1?.['P3 HZ']?.ioValues?.high_motor_temperature,
                    locationEquipment1?.['P3 HZ']?.ioValues?.low_suction_pressure_fault,
                    locationEquipment1?.['P3 HZ']?.ioValues?.modbus_communication_error,
                  ]}
                  stats={[
                    {
                      stat: locationEquipment1?.['P3 HZ']?.ioValues?.current?.value,
                      label: locationEquipment1?.['P3 HZ']?.ioValues?.current?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment1?.['P3 HZ']?.ioValues?.run_time?.value,
                      label: locationEquipment1?.['P3 HZ']?.ioValues?.run_time?.ioValueType?.units,
                    },
                  ]}
                  statuses={[
                    {
                      id: locationEquipment1?.['P3 HZ']?.ioValues?.status?.id,
                      status: locationEquipment1?.['P3 HZ']?.ioValues?.status?.value,
                      statusKey: pumpMainStatusKey,
                      statusColorKey: pumpMainStatusColorKey,
                    },
                    {
                      id: locationEquipment1?.['P3 HZ']?.ioValues?.auto_enabled?.id,
                      status: locationEquipment1?.['P3 HZ']?.ioValues?.auto_enabled?.value,
                      statusKey: {
                        0: '',
                        1: language.auto,
                      },
                      statusColorKey: {
                        0: '',
                        1: theme.auto,
                      },
                    },
                  ]}
                />
              )}
              {p3HighLow === 'low' && (
                <Pump
                  pipeBelow="middle"
                  localRemote
                  buttonText1="HZ"
                  buttonText2="LZ"
                  controlPositionTop="-60px"
                  setIOValue={props.setIOValue}
                  pumpData={{
                    ...locationEquipment1?.['P3 LZ'],
                    name: 'P3',
                    ioValues: {
                      ...locationEquipment1?.['P3 LZ']?.ioValues,
                      local: locationEquipment1?.['P3 HZ']?.ioValues?.hz_disable_enable,
                      remote: locationEquipment1?.['P3 HZ']?.ioValues?.lz_disable_enable,
                    },
                  }}
                  otherData={locationEquipment1?.['Other LZ']}
                  stateKey={{
                    0: language.off,
                    1: language.on,
                    2: language.warning,
                    3: language.alarm,
                  }}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    start: 'disable_enable',
                    stop: 'disable_enable',
                    started: 'enabled',
                    stopped: 'enabled',
                    local: 'local',
                    remote: 'remote',
                    speed: 'speed',
                  }}
                  writeValues={[
                    1,
                    0,
                    locationEquipment1?.['P3 HZ']?.ioValues?.hz_disable_enable?.value === 1 ? 0 : 1,
                    locationEquipment1?.['P3 HZ']?.ioValues?.lz_disable_enable?.value === 1 ? 0 : 1,
                  ]}
                  readValues={[1, 0, 1, 1]}
                  alarms={[
                    locationEquipment1?.['P3 LZ']?.ioValues?.high_seal_temperature,
                    locationEquipment1?.['P3 LZ']?.ioValues?.low_suction_pressure,
                    locationEquipment1?.['P3 LZ']?.ioValues?.fail_to_run,
                    locationEquipment1?.['P3 LZ']?.ioValues?.speed_signal_fault,
                    locationEquipment1?.['P3 LZ']?.ioValues?.high_motor_temperature,
                    locationEquipment1?.['P3 LZ']?.ioValues?.low_suction_pressure_fault,
                    locationEquipment1?.['P3 LZ']?.ioValues?.modbus_communication_error,
                  ]}
                  stats={[
                    {
                      stat: locationEquipment1?.['P3 LZ']?.ioValues?.current?.value,
                      label: locationEquipment1?.['P3 LZ']?.ioValues?.current?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment1?.['P3 LZ']?.ioValues?.run_time?.value,
                      label: locationEquipment1?.['P3 LZ']?.ioValues?.run_time?.ioValueType?.units,
                    },
                  ]}
                  statuses={[
                    {
                      id: locationEquipment1?.['P3 LZ']?.ioValues?.status?.id,
                      status: locationEquipment1?.['P3 LZ']?.ioValues?.status?.value,
                      statusKey: pumpMainStatusKey,
                      statusColorKey: pumpMainStatusColorKey,
                    },
                    {
                      id: locationEquipment1?.['P3 LZ']?.ioValues?.auto_enabled?.id,
                      status: locationEquipment1?.['P3 LZ']?.ioValues?.auto_enabled?.value,
                      statusKey: {
                        0: '',
                        1: language.auto,
                      },
                      statusColorKey: {
                        0: '',
                        1: theme.auto,
                      },
                    },
                  ]}
                />
              )}
              <div css={styles.valveCorner}>
                <PipePlaceholder horizontalBelow minWidth="0px" />
              </div>
              <Pump
                pipeBelow="middle"
                controlPositionTop="-60px"
                setIOValue={props.setIOValue}
                pumpData={{
                  ...locationEquipment1?.['P2 LZ'],
                  name: 'P2',
                  ioValues: {
                    ...locationEquipment1?.['P2 LZ']?.ioValues,
                  },
                }}
                otherData={locationEquipment1?.['Other LZ']}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  started: 'enabled',
                  stopped: 'enabled',
                  speed: 'speed',
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment1?.['P2 LZ']?.ioValues?.high_seal_temperature,
                  locationEquipment1?.['P2 LZ']?.ioValues?.low_suction_pressure,
                  locationEquipment1?.['P2 LZ']?.ioValues?.fail_to_run,
                  locationEquipment1?.['P2 LZ']?.ioValues?.speed_signal_fault,
                  locationEquipment1?.['P2 LZ']?.ioValues?.high_motor_temperature,
                  locationEquipment1?.['P2 LZ']?.ioValues?.low_suction_pressure_fault,
                  locationEquipment1?.['P2 LZ']?.ioValues?.modbus_communication_error,
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.['P2 LZ']?.ioValues?.current?.value,
                    label: locationEquipment1?.['P2 LZ']?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.['P2 LZ']?.ioValues?.run_time?.value,
                    label: locationEquipment1?.['P2 LZ']?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
                statuses={[
                  {
                    id: locationEquipment1?.['P2 LZ']?.ioValues?.status?.id,
                    status: locationEquipment1?.['P2 LZ']?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                  {
                    id: locationEquipment1?.['P2 LZ']?.ioValues?.auto_enabled?.id,
                    status: locationEquipment1?.['P2 LZ']?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: language.auto,
                    },
                    statusColorKey: {
                      0: '',
                      1: theme.auto,
                    },
                  },
                ]}
              />
              <div css={styles.valveCorner}>
                <PipePlaceholder horizontalBelow minWidth="0px" />
              </div>
              {p1HighLow === 'high' && (
                <Pump
                  pipeBelow="middle"
                  localRemote
                  buttonText1="HZ"
                  buttonText2="LZ"
                  controlPositionTop="-60px"
                  setIOValue={props.setIOValue}
                  pumpData={{
                    ...locationEquipment1?.['P1 HZ'],
                    name: 'P1',
                    ioValues: {
                      ...locationEquipment1?.['P1 HZ']?.ioValues,
                      start: locationEquipment1?.['P1 LZ']?.ioValues?.disable_enable,
                      stop: locationEquipment1?.['P1 LZ']?.ioValues?.disable_enable,
                      local: locationEquipment1?.['P1 HZ']?.ioValues?.hz_disable_enable,
                      remote: locationEquipment1?.['P1 HZ']?.ioValues?.lz_disable_enable,
                    },
                  }}
                  otherData={locationEquipment1?.['Other HZ']}
                  stateKey={{
                    0: language.off,
                    1: language.on,
                    2: language.warning,
                    3: language.alarm,
                  }}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    start: 'start',
                    stop: 'stop',
                    started: 'enabled',
                    stopped: 'enabled',
                    local: 'local',
                    remote: 'remote',
                    speed: 'speed',
                  }}
                  writeValues={[
                    1,
                    0,
                    locationEquipment1?.['P1 HZ']?.ioValues?.hz_disable_enable?.value === 1 ? 0 : 1,
                    locationEquipment1?.['P1 HZ']?.ioValues?.lz_disable_enable?.value === 1 ? 0 : 1,
                  ]}
                  readValues={[1, 0, 1, 1]}
                  alarms={[
                    locationEquipment1?.['P1 HZ']?.ioValues?.high_seal_temperature,
                    locationEquipment1?.['P1 HZ']?.ioValues?.low_suction_pressure,
                    locationEquipment1?.['P1 HZ']?.ioValues?.fail_to_run,
                    locationEquipment1?.['P1 HZ']?.ioValues?.speed_signal_fault,
                    locationEquipment1?.['P1 HZ']?.ioValues?.high_motor_temperature,
                    locationEquipment1?.['P1 HZ']?.ioValues?.low_suction_pressure_fault,
                    locationEquipment1?.['P1 HZ']?.ioValues?.modbus_communication_error,
                  ]}
                  stats={[
                    {
                      stat: locationEquipment1?.['P1 HZ']?.ioValues?.current?.value,
                      label: locationEquipment1?.['P1 HZ']?.ioValues?.current?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment1?.['P1 HZ']?.ioValues?.run_time?.value,
                      label: locationEquipment1?.['P1 HZ']?.ioValues?.run_time?.ioValueType?.units,
                    },
                  ]}
                  statuses={[
                    {
                      id: locationEquipment1?.['P1 HZ']?.ioValues?.status?.id,
                      status: locationEquipment1?.['P1 HZ']?.ioValues?.status?.value,
                      statusKey: pumpMainStatusKey,
                      statusColorKey: pumpMainStatusColorKey,
                    },
                    {
                      id: locationEquipment1?.['P1 HZ']?.ioValues?.auto_enabled?.id,
                      status: locationEquipment1?.['P1 HZ']?.ioValues?.auto_enabled?.value,
                      statusKey: {
                        0: '',
                        1: language.auto,
                      },
                      statusColorKey: {
                        0: '',
                        1: theme.auto,
                      },
                    },
                  ]}
                />
              )}
              {p1HighLow === 'low' && (
                <Pump
                  pipeBelow="middle"
                  localRemote
                  buttonText1="HZ"
                  buttonText2="LZ"
                  controlPositionTop="-60px"
                  setIOValue={props.setIOValue}
                  pumpData={{
                    ...locationEquipment1?.['P1 LZ'],
                    name: 'P1',
                    ioValues: {
                      ...locationEquipment1?.['P1 LZ']?.ioValues,
                      local: locationEquipment1?.['P1 HZ']?.ioValues?.hz_disable_enable,
                      remote: locationEquipment1?.['P1 HZ']?.ioValues?.lz_disable_enable,
                    },
                  }}
                  otherData={locationEquipment1?.['Other LZ']}
                  stateKey={{
                    0: language.off,
                    1: language.on,
                    2: language.warning,
                    3: language.alarm,
                  }}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  IOValueKeys={{
                    start: 'disable_enable',
                    stop: 'disable_enable',
                    started: 'enabled',
                    stopped: 'enabled',
                    local: 'local',
                    remote: 'remote',
                    speed: 'speed',
                  }}
                  writeValues={[
                    1,
                    0,
                    locationEquipment1?.['P1 HZ']?.ioValues?.hz_disable_enable?.value === 1 ? 0 : 1,
                    locationEquipment1?.['P1 HZ']?.ioValues?.lz_disable_enable?.value === 1 ? 0 : 1,
                  ]}
                  readValues={[1, 0, 1, 1]}
                  alarms={[
                    locationEquipment1?.['P1 LZ']?.ioValues?.high_seal_temperature,
                    locationEquipment1?.['P1 LZ']?.ioValues?.low_suction_pressure,
                    locationEquipment1?.['P1 LZ']?.ioValues?.fail_to_run,
                    locationEquipment1?.['P1 LZ']?.ioValues?.speed_signal_fault,
                    locationEquipment1?.['P1 LZ']?.ioValues?.high_motor_temperature,
                    locationEquipment1?.['P1 LZ']?.ioValues?.low_suction_pressure_fault,
                    locationEquipment1?.['P1 LZ']?.ioValues?.modbus_communication_error,
                  ]}
                  stats={[
                    {
                      stat: locationEquipment1?.['P1 LZ']?.ioValues?.current?.value,
                      label: locationEquipment1?.['P1 LZ']?.ioValues?.current?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment1?.['P1 LZ']?.ioValues?.run_time?.value,
                      label: locationEquipment1?.['P1 LZ']?.ioValues?.run_time?.ioValueType?.units,
                    },
                  ]}
                  statuses={[
                    {
                      id: locationEquipment1?.['P1 LZ']?.ioValues?.status?.id,
                      status: locationEquipment1?.['P1 LZ']?.ioValues?.status?.value,
                      statusKey: pumpMainStatusKey,
                      statusColorKey: pumpMainStatusColorKey,
                    },
                    {
                      id: locationEquipment1?.['P1 LZ']?.ioValues?.auto_enabled?.id,
                      status: locationEquipment1?.['P1 LZ']?.ioValues?.auto_enabled?.value,
                      statusKey: {
                        0: '',
                        1: language.auto,
                      },
                      statusColorKey: {
                        0: '',
                        1: theme.auto,
                      },
                    },
                  ]}
                />
              )}
              <div css={styles.flexRowBox}>
                <PipePlaceholder horizontalBelow minWidth="0px" />
                <div css={[css`display: flex; flex-direction: column; justify-content: flex-end; margin-bottom: -2px;`, styles.hiddenMobile]}>
                  <Statistic
                    border
                    statistic={locationEquipment1?.['Other LZ']?.ioValues?.suction_water_pressure?.value}
                    label={locationEquipment1?.['Other LZ']?.ioValues?.suction_water_pressure?.ioValueType?.units}
                  />
                </div>
                <PipePlaceholder horizontalBelow minWidth="0px" capAfter capLabel="From Lake PH" capLabelLeft="-105px" capLabelTop="-25px" />
              </div>
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    flexRowBox: css`
      display: flex;
      flex: 1;
      height: 100%;
      min-width: 175px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
    drain: css`
      display: flex;
      height: 50px;
      flex: 1;
      width: 150px;
  `,
    drainsColumn: css`
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      align-items: flex-start;
  `,
    valveCorner: css`
      display: flex;
      height: 100%;
      width: 75px;
  `,
  };
};

PrimaryScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,
};

PrimaryScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
  settings: state.settings,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(PrimaryScreen);
