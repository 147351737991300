import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, css } from '@emotion/react';

import {
  Pump,
  Pump2,
  Valve,
  ValveConnect,
  Pipe,
  PipeCurve,
  PipePlaceholder,
  Pond,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRow,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  PumpsRowMedium,
  PumpsRowCustom,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Weather,
  Info,
  // Totals,
  Statistic,
  MobileStatTable,
  MultiColInfo,
  MultiInfo,
  PLCStateRouter,
  PLCColorRouter,
  LoadingIndicator,
  Alarms,
} from 'isno/lib/components/displays';

import {
  AutoValveControl,
  VFDPumpControl,
  ToggleControl,
  AlarmReset,
  InfoButton,
  TextInputForm,
  renderButtons,
  SnomaxControl,
  MultiSetpointControl,
  MultiInfoButton,
  SequenceControl,
} from 'isno/lib/components/controls';

import { startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow',
  suctionWaterPressure: 'suction_water_pressure',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function LakeScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(2),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(2);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const pumpMainStatusKey = {
    0: 'READY',
    1: 'RUN',
    2: 'Switch',
    3: 'High Temp',
    4: 'Low Level',
    5: 'Run Fail',
    6: 'Disabled',
    7: 'Comm',
    8: 'Local',
    9: 'Fault',
    10: 'Suction',
    11: 'PRIMARY',
  };

  const pumpMainStatusColorKey = {
    0: `${theme.onGreen}`,
    1: `${theme.onGreen}`,
    2: `${theme.alarm}`,
    3: `${theme.alarm}`,
    4: `${theme.alarm}`,
    5: `${theme.alarm}`,
    6: `${theme.alarm}`,
    7: `${theme.alarm}`,
    8: `${theme.alarm}`,
    9: `${theme.alarm}`,
    10: `${theme.alarm}`,
    11: `${theme.onGreen}`,
  };

  const locationEquipment2 = props.locations?.locations?.[2]?.equipment;

  if (!props.locations?.locations?.[2]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  const useCelsius = props.settings?.settings?.useCelsius;

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer height="fit-content" flex="3" border label="Lake Pumphouse">
            <InfoControlRow>
              <VFDPumpControl
                title="PID Control"
                pumpData={{ id: `controlValve_${locationEquipment2?.PID?.id}` }}
                pidData={locationEquipment2?.PID}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_mode',
                  manualMode: 'manual_mode',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                }}
                readValues={[1, 1]}
                writeValues={[0, 1]}
              />
              <Info
                title="PID Fill Mode"
                statistic={locationEquipment2?.PID?.ioValues?.fill_mode_done?.value === 1 ? 'Done' : 'Filling'}
                color={locationEquipment2?.PID?.ioValues?.fill_mode_done?.value === 1 ? theme.onGreen : theme.waterColor}
              />
              <div css={css`display: inline-grid; flex-direction: column;`}>
                <Info
                  title="Voltage"
                  statistic={locationEquipment2?.Other?.ioValues?.voltage?.value}
                  label={locationEquipment2?.Other?.ioValues?.voltage?.ioValueType?.units}
                />
                <Info
                  title="Current"
                  statistic={locationEquipment2?.Other?.ioValues?.current?.value}
                  label={locationEquipment2?.Other?.ioValues?.current?.ioValueType?.units}
                />
              </div>
              <MultiInfo
                title="Pump Timers"
                subtitles={['Next Start', 'Next Stop']}
                statistics={[
                  locationEquipment2?.Other?.ioValues?.pump_starting_timer?.value,
                  locationEquipment2?.Other?.ioValues?.pump_stopping_timer?.value,
                ]}
                ids={[
                  locationEquipment2?.Other?.ioValues?.pump_starting_timer?.id,
                  locationEquipment2?.Other?.ioValues?.pump_stopping_timer?.id,
                ]}
                labels={[
                  locationEquipment2?.Other?.ioValues?.pump_starting_timer?.ioValueType?.units,
                  locationEquipment2?.Other?.ioValues?.pump_stopping_timer?.ioValueType?.units,
                ]}
              />
              <MultiColInfo
                title="Discharge Flow Volumes"
                firstColSubtitles={['Today', 'Monthly', 'Yearly']}
                secondColSubtitles={['Yesterday', 'Last Month', 'Last Year']}
                thirdColSubtitles={['Total']}
                firstColStatistics={[
                  locationEquipment2?.FT?.ioValues?.daily_volume?.value,
                  locationEquipment2?.FT?.ioValues?.monthly_volume?.value,
                  locationEquipment2?.FT?.ioValues?.yearly_volume?.value,
                ]}
                secondColStatistics={[
                  locationEquipment2?.FT?.ioValues?.yesterday_volume?.value,
                  locationEquipment2?.FT?.ioValues?.last_month_volume?.value,
                  locationEquipment2?.FT?.ioValues?.last_year_volume?.value,
                ]}
                thirdColStatistics={[
                  locationEquipment2?.FT?.ioValues?.total_volume?.value,
                ]}
                firstColLabels={[
                  locationEquipment2?.FT?.ioValues?.daily_volume?.ioValueType?.units,
                  locationEquipment2?.FT?.ioValues?.monthly_volume?.ioValueType?.units,
                  locationEquipment2?.FT?.ioValues?.yearly_volume?.ioValueType?.units,
                ]}
                secondColLabels={[
                  locationEquipment2?.FT?.ioValues?.yesterday_volume?.ioValueType?.units,
                  locationEquipment2?.FT?.ioValues?.last_month_volume?.ioValueType?.units,
                  locationEquipment2?.FT?.ioValues?.last_year_volume?.ioValueType?.units,
                ]}
                thirdColLabels={[
                  locationEquipment2?.FT?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                firstColIds={[
                  locationEquipment2?.FT?.ioValues?.daily_volume?.id,
                  locationEquipment2?.FT?.ioValues?.monthly_volume?.id,
                  locationEquipment2?.FT?.ioValues?.yearly_volume?.id,
                ]}
                secondColIds={[
                  locationEquipment2?.FT?.ioValues?.yesterday_volume?.id,
                  locationEquipment2?.FT?.ioValues?.last_month_volume?.id,
                  locationEquipment2?.FT?.ioValues?.last_year_volume?.id,
                ]}
                thirdColIds={[
                  locationEquipment2?.FT?.ioValues?.total_volume?.id,
                ]}
                firstColPrecisions={[0, 0, 0]}
                secondColPrecisions={[0, 0, 0]}
                thirdColPrecisions={[1]}
              />
              <InfoButton
                title="Trip Vol."
                statistic={locationEquipment2?.FT?.ioValues?.trip_volume?.value}
                label={locationEquipment2?.FT?.ioValues?.trip_volume?.ioValueType?.units}
                precision={0}
                buttonWriteId={locationEquipment2?.FT?.ioValues?.trip_volume_reset?.id}
                buttonText="Reset"
                setIOValue={props.setIOValue}
              />
              <Info
                title="Room Temp."
                statistic={useCelsius ? locationEquipment2?.Other?.ioValues?.room_temperature_alternate?.value : locationEquipment2?.Other?.ioValues?.room_temperature?.value}
                label={useCelsius ? locationEquipment2?.Other?.ioValues?.room_temperature_alternate?.ioValueType?.units : locationEquipment2?.Other?.ioValues?.room_temperature?.ioValueType?.units}
                precision={1}
              />
              <Alarms
                title="General Alarms"
                ioValues={locationEquipment2?.Other?.ioValues}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationEquipment2?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'alarm_reset',
                }}
                resetWriteValue={1}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[3], language)}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[3], theme)}
              />
              <MobileStatTable
                title="Water Metrics"
                stats={
                [
                  {
                    description: 'Discharge Water PSI',
                    stat: locationEquipment2?.Other?.ioValues.discharge_water_pressure?.value,
                    label: locationEquipment2?.Other?.ioValues.discharge_water_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Discharge Water Flow',
                    stat: locationEquipment2?.Other?.ioValues.discharge_water_flow?.value,
                    label: locationEquipment2?.Other?.ioValues.discharge_water_flow?.ioValueType?.units,
                  },
                  {
                    description: 'Discharge Water Temp.',
                    stat: useCelsius ? locationEquipment2?.Other?.ioValues.discharge_water_temperature_alternate?.value : locationEquipment2?.Other?.ioValues.discharge_water_temperature?.value,
                    label: useCelsius ? locationEquipment2?.Other?.ioValues.discharge_water_temperature_alternate?.ioValueType?.units : locationEquipment2?.Other?.ioValues.discharge_water_temperature?.ioValueType?.units,
                    precision: 1,
                  },
                ]
              }
              />
            </InfoControlRow>
            <PumpsRowCustom height="60px">
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <PipePlaceholder pipeAbove="right" />
              <div css={styles.flexRow}>
                <PipePlaceholder horizontalAbove minWidth="0px" />
                <div css={[css`height: 43px; margin-top: -3px; display: flex; flex-direction: row;`, styles.hiddenMobile]}>
                  <Statistic
                    statistic={locationEquipment2?.Other?.ioValues?.discharge_water_pressure?.value}
                    border
                    label={locationEquipment2?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units}
                  />
                  <Statistic
                    statistic={locationEquipment2?.Other?.ioValues?.discharge_water_flow?.value}
                    border
                    label={locationEquipment2?.Other?.ioValues?.discharge_water_flow?.ioValueType?.units}
                  />
                  <Statistic
                    label={useCelsius ? locationEquipment2?.Other?.ioValues?.discharge_water_temperature_alternate?.ioValueType?.units : locationEquipment2?.Other?.ioValues?.discharge_water_temperature?.ioValueType?.units}
                    border
                    statistic={useCelsius ? locationEquipment2?.Other?.ioValues?.discharge_water_temperature_alternate?.value : locationEquipment2?.Other?.ioValues?.discharge_water_temperature?.value}
                    precision={1}
                  />
                </div>
                <PipePlaceholder horizontalAbove minWidth="0px" capAfter capLabel="To Upper PH" capLabelLeft="-105px" capLabelTop="19px" />
              </div>
            </PumpsRowCustom>
            <PumpsRowMedium>
              <PipePlaceholder hidden />
              <Pump
                pipeAbove="right"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment2?.P1}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  started: 'enabled',
                  stopped: 'enabled',
                  speed: 'speed',
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment2?.P1?.ioValues?.high_seal_temperature,
                  locationEquipment2?.P1?.ioValues?.low_suction_pressure,
                  locationEquipment2?.P1?.ioValues?.speed_signal_fault,
                  locationEquipment2?.P1?.ioValues?.fail_to_run,
                  locationEquipment2?.P1?.ioValues?.high_motor_temperature,
                  locationEquipment2?.P1?.ioValues?.low_suction_pressure_fault,
                  locationEquipment2?.P1?.ioValues?.modbus_communication_error,
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.P1?.ioValues?.current?.value,
                    label: locationEquipment2?.P1?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.P1?.ioValues?.run_time?.value,
                    label: locationEquipment2?.P1?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
                statuses={[
                  {
                    id: locationEquipment2?.P1?.ioValues?.status?.id,
                    status: locationEquipment2?.P1?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                  {
                    id: locationEquipment2?.P1?.ioValues?.auto_enabled?.id,
                    status: locationEquipment2?.P1?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: language.auto,
                    },
                    statusColorKey: {
                      0: '',
                      1: theme.auto,
                    },
                  },
                ]}
              />
              <Pump
                pipeAbove="middle"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment2?.P2}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  started: 'enabled',
                  stopped: 'enabled',
                  speed: 'speed',
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment2?.P2?.ioValues?.high_seal_temperature,
                  locationEquipment2?.P2?.ioValues?.low_suction_pressure,
                  locationEquipment2?.P2?.ioValues?.speed_signal_fault,
                  locationEquipment2?.P2?.ioValues?.fail_to_run,
                  locationEquipment2?.P2?.ioValues?.high_motor_temperature,
                  locationEquipment2?.P2?.ioValues?.low_suction_pressure_fault,
                  locationEquipment2?.P2?.ioValues?.modbus_communication_error,
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.P2?.ioValues?.current?.value,
                    label: locationEquipment2?.P2?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.P2?.ioValues?.run_time?.value,
                    label: locationEquipment2?.P2?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
                statuses={[
                  {
                    id: locationEquipment2?.P2?.ioValues?.status?.id,
                    status: locationEquipment2?.P2?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                  {
                    id: locationEquipment2?.P2?.ioValues?.auto_enabled?.id,
                    status: locationEquipment2?.P2?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: language.auto,
                    },
                    statusColorKey: {
                      0: '',
                      1: theme.auto,
                    },
                  },
                ]}
              />
              <Pump
                pipeAbove="left"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment2?.P3}
                otherData={locationEquipment2?.Other}
                stateKey={{
                  0: language.off,
                  1: language.on,
                  2: language.warning,
                  3: language.alarm,
                }}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  started: 'enabled',
                  stopped: 'enabled',
                  speed: 'speed',
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment2?.P3?.ioValues?.high_seal_temperature,
                  locationEquipment2?.P3?.ioValues?.low_suction_pressure,
                  locationEquipment2?.P3?.ioValues?.speed_signal_fault,
                  locationEquipment2?.P3?.ioValues?.fail_to_run,
                  locationEquipment2?.P3?.ioValues?.high_motor_temperature,
                  locationEquipment2?.P3?.ioValues?.low_suction_pressure_fault,
                  locationEquipment2?.P3?.ioValues?.modbus_communication_error,
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.P3?.ioValues?.current?.value,
                    label: locationEquipment2?.P3?.ioValues?.current?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.P3?.ioValues?.run_time?.value,
                    label: locationEquipment2?.P3?.ioValues?.run_time?.ioValueType?.units,
                  },
                ]}
                statuses={[
                  {
                    id: locationEquipment2?.P3?.ioValues?.status?.id,
                    status: locationEquipment2?.P3?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                  {
                    id: locationEquipment2?.P3?.ioValues?.auto_enabled?.id,
                    status: locationEquipment2?.P3?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: language.auto,
                    },
                    statusColorKey: {
                      0: '',
                      1: theme.auto,
                    },
                  },
                ]}
              />
            </PumpsRowMedium>
            <PumpsRowCustom height="65px">
              <Pond
                flex="25"
                capBefore
                capBeforeLabel="From Lake"
                capBeforeLabelTop="4px"
                capBeforeLabelLeft="60px"
                capBeforeLabelColor="white"
                color={theme.pipeColors.water}
              >
                {/* <Info
                  title="Well Level"
                  statistic={locationEquipment2?.Other?.ioValues?.well_level?.value}
                  precision={1}
                  label={locationEquipment2?.Other?.ioValues?.well_level?.ioValueType?.units}
                  margin="1px 0px 0px 0px"
                  // alertText={(() => {
                  //   if (locationEquipment2?.Other?.ioValues?.low_well_level?.value) {
                  //     return 'Low Well Level';
                  //   } else if (locationEquipment2?.Other?.ioValues?.low_well_level_warning?.value) {
                  //     return 'Low Well Level Warning';
                  //   } else {
                  //     return null;
                  //   }
                  // })()}
                  // alertTop="50px"
                  // alertLeft={locationEquipment2?.Other?.ioValues?.low_well_level_warning?.value ? '-50px' : '-15px'}
                /> */}
              </Pond>
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      min-width: 175px;
      display: flex;
      flex: 1;
      flex-direction: row;
      height: 100%;
    `,
    snomaxBox: css`
      border: 1px solid gray;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      height: 175px;
      width: 251px;
    `,
    flexCol: css`
      min-width: 175px;
      display: flex;
      flex: 1;
      flex-direction: column;
      height: 100%;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
    container: css`
      display: flex;
      height: 100%;
      flex: 1;
      max-width: 60px;
      // margin: 0px 32px 10px 10px;
      position: relative;
      justify-content: flex-start;
      flex-direction: column;
      background-color: ${theme.containerBackgroundColor};
      border: 1px solid ${theme.borderColor};
      border-radius: 4px;
      overflow: visible;
    `,
    header: css`
      font-weight: bold;
      padding: 0 10px;
      border-radius: 2px 2px 0px 0px;
      background-color: ${theme.headerColor};
      text-align: center;
    `,
  };
};

LakeScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,
};

LakeScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
  settings: state.settings,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(LakeScreen);
